<template>
  <div class="homeMind">
    <div class="homeMindBody">
      <div class="homeMindBttn">咨询客服</div>
      <div class="homeMenu">
        <div class="homeMenuLeft">
          <img
            src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230109144529_logo.png"
            class="logoImg"
          />
        </div>
        <div class="homeMenuRow">
          <div class="homeMenuRowLeft">
            <div
              class="menuItem"
              :class="menuId == item.id ? 'activeMenu' : ''"
              v-for="item in menuList"
              :key="item.id"
              @click="menuClick(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="homeMenuRowRigth">
            <img src="@/assets/user.png" class="userImg" />
            <div class="homeMenuRowRigthBtn" @click="loginClick">登录</div>
          </div>
        </div>
      </div>
      <div class="homebody">
        <router-view></router-view>
      </div>
      <div class="homeBttom">
        <div class="homeBttomOne">
          <div class="homeBttomOneTitle">
            <img
              src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230109144529_logo.png"
              class="logoImg"
            />
            <div class="homeBttomOneTitleRight">
              <div class="homeBttomOneTitleRighttext">
                安徽英制马力信息技术有限公司
              </div>
              <div class="homeBttomOneTitleRighttextOne">
                ANHUI YINGZHU MA LI INFORMATION TECHNOLOGY CO. LT
              </div>
            </div>
          </div>
          <div class="homeBttomRow">
            <div class="homeBttomRowLeft">
              <div class="homeBttomRowLeftText">
                <div>联系我们</div>
                <div>热线电话：400 189 8820</div>
                <div>电子邮箱：2710850@qq.com</div>
                <div>公司地址：合肥市创新产业期一期A2栋</div>
              </div>
              <div class="homeBttomRowLeftEwm">
                <div class="homeBttomRowLeftEwmTop">
                  <img
                    src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110163715_weixin.png"
                    class="weixinImg"
                  />
                  <div>微信咨询</div>
                </div>
                <img
                  src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230110163906_ewm.png"
                  class="erwmImg"
                />
              </div>
            </div>
            <div class="homeBttomRowRight">
              <div
                class="homeBttomRowRightItem"
                v-for="s in menuBtn"
                :key="s.id"
              >
                <div>{{ s.name }}</div>
                <div v-for="i in s.list" :key="i.id">{{ i.title }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="homeBttomRecord"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        { id: 0, name: "首页" },
        { id: 1, name: "产品中心" },
        { id: 2, name: "解决方案" },
        { id: 3, name: "智慧商城" },
        { id: 4, name: "关于我们" },
      ],
      menuId: 0,
      menuBtn: [
        {
          id: 0,
          name: "首页",
          list: [
            { id: 11, title: "产品介绍" },
            { id: 12, title: "企业介绍" },
            { id: 13, title: "智慧信访" },
          ],
        },
        {
          id: 1,
          name: "产品中心",
          list: [
            { id: 14, title: "智慧旅游" },
            { id: 15, title: "智慧康养" },
            { id: 16, title: "智慧信访" },
          ],
        },
        { id: 2, name: "解决方案", list: [] },
        { id: 3, name: "智慧商城", list: [] },
        {
          id: 4,
          name: "关于我们",
          list: [
            { id: 17, title: "公司介绍" },
            { id: 18, title: "公司资质" },
            { id: 19, title: "发展历程" },
          ],
        },
      ],
    };
  },
  created() {
    this.$router.push({ path: "/login" });
  },
  methods: {
    menuClick(s) {
      this.menuId = s.id;
      if (s.id == 0) {
        this.$router.push({ path: "/login" });
      } else if (s.id == 1) {
        this.$router.push({ path: "/product" });
      } else if (s.id == 2) {
        this.$router.push({ path: "/solution" });
      } else if (s.id == 3) {
        this.$router.push({ path: "/shopping" });
      } else if (s.id == 4) {
        this.$router.push({ path: "/about" });
      }
    },
    loginClick() {
      this.$router.push({ path: "/layout" });
    },
  },
};
</script>

<style>
.homeMind {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 0px;
  box-sizing: border-box;
  background-color: #272b2e;
}

.homeMindBody {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.homeMindBttn {
  position: fixed;
  top: 600px;
  right: 0px;
  z-index: 40;
  width: 140px;
  height: 40px;
  background-color: #2680eb;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  text-align: center;
  line-height: 40px;
  color: white;
  font-size: 20px;
}

.homeMenu {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 40;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  box-sizing: border-box;
}

.homeMenuLeft {
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoImg {
  width: 80px;
  height: 51px;
}

.homeMenuRow {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.homeMenuRowLeft {
  width: 450px;
  height: 100%;
  display: flex;
}

.menuItem {
  width: 90px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  color: #979797;
  font-size: 16px;
}

.menuItem:hover {
  cursor: pointer;
}

.activeMenu {
  border-bottom: 4px solid #1764fc;
  color: #1764fc;
}

.homeMenuRowRigth {
  width: 120px;
  height: 100%;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.userImg {
  width: 19px;
  height: 20px;
  margin-right: 15px;
}

.homeMenuRowRigthBtn {
  width: 80px;
  color: #3d7eff;
  font-size: 16px;
}

.homeMenuRowRigthBtn:hover {
  cursor: pointer;
}

.homebody {
  width: 100%;
  min-height: calc(100% - 500px);
  display: block;
}

.homeBttom {
  width: 100%;
  height: 500px;
  background-color: #272b2e;
  padding: 20px 360px;
  box-sizing: border-box;
  display: block;
}

.homeBttomOne {
  width: 100%;
  height: 340px;
  border-bottom: 1px solid #707070;
  padding: 0px 5px;
  box-sizing: border-box;
}

.homeBttomOneTitle {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
}

.homeBttomOneTitleRight {
  width: 500px;
  height: 80px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  color: white;
}

.homeBttomOneTitleRighttext {
  font-size: 20px;
}

.homeBttomOneTitleRighttextOne {
  font-size: 12px;
}

.homeBttomRow {
  width: 100%;
  height: calc(100% - 80px);
  padding: 40px 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.homeBttomRowLeft {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}

.homeBttomRowLeftText {
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  font-size: 16px;
  border-right: 1px solid #707070;
}

.homeBttomRowLeftEwm {
  width: 140px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homeBttomRowLeftEwmTop {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
}

.weixinImg {
  width: 20px;
  height: 16px;
  margin-right: 10px;
}

.erwmImg {
  width: 95px;
  height: 95px;
  margin-top: 20px;
}

.homeBttomRowRight {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  color: white;
  font-size: 16px;
}

.homeBttomRowRightItem {
  width: 98px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.homeBttomRecord {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
}
</style>